import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import logo from '../../Assets/faith_logo_complete.svg';
import arrow from '../../Assets/Icons/arrow-side.svg';
import dashboard from '../../Assets/Icons/dashboard.svg';
import alarm from '../../Assets/Icons/search-icon.svg'
import hamb from '../../Assets/Icons/hamb-icon.svg';
import { signOut } from 'aws-amplify/auth';
import { Button } from '@aws-amplify/ui-react';


const SideContainer = () => {
    const [ menu, setMenu ] = useState(false);
    const menuRef = useRef();

    useEffect(() => {
        let handler = (event) => {
            if(!menuRef.current.contains(event.target)){
                setTimeout(() => {
                    setMenu(false);
                }, 300);
            } 
        }
    
        document.addEventListener("mouseover", handler)
    
        return () => {
            document.removeEventListener("mouseover", handler)
        }
    }, [])

      const patientSignOut = () => {
        const User_Authentication_Info = async () => {
            await signOut();
            localStorage.clear();
            window.location.reload();
        }
        User_Authentication_Info();
    }

   return (
    <>
    {!menu ?
        <div className='side-container-small' ref={menuRef}>
            <img className='pointer' src={hamb} onClick={() => setMenu(true)}/>
        </div>
    :
        <div className='side-container' ref={menuRef}>
            <div style={{justifyContent: 'flex-start', paddingLeft: 2}}>
                <img src={logo} alt="logo" className='logoSide' />
            </div>
            
            <div className='side-container-options'>
                <ul>
                    <NavLink to="/predictors" >
                        <li className={'white-txt medium py'}>
                            <img src={alarm} alt="predictors" className='mr' />
                            Predictors
                            <img src={arrow} alt="arrow" className='arrow ml' />
                        </li>
                    </NavLink>
                </ul>
                <ul>
                    <NavLink to="/detailedpredictors" >
                        <li className={'white-txt medium py'}>
                            <img src={dashboard} alt="detailedpredictors" className='mr' />
                            Detailed predictors
                            <img src={arrow} alt="arrow" className='arrow ml' />
                        </li>
                    </NavLink>
                </ul>
            </div>
            <div style={{margin: 'auto', 'width': '35%', 'height': 'auto', 'borderRadius': '5px'}}>
                <Button style={{'background': 'white'}} onClick={patientSignOut}>Sign out</Button>
            </div>
        </div>
    }
    
    </>
  )
};
export default SideContainer;